import React from 'react';
import a from './img1.png'
import b from './img2.png'

const SecuritySolutionsOffer = () => {
    return (
        <section className="security-solutions-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Secure Development and Operations Practices</h2>
                        <p className="lead">
                        Adopting secure Devops practices is akin to building a sturdy foundation for a house. It ensures that our code is resilient to common vulnerabilities such as injection attacks, cross-site scripting, and insecure dependencies. By following best practices and staying informed about security updates, we fortify our applications against potential exploits.
                        </p>
                    </div>
                </div>

                {/* Cybersecurity Services Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Data Protection</h3>
                        <p>
                        As custodians of user data, it's our duty to implement robust data protection measures. Encryption, both in transit and at rest, is a fundamental aspect of shielding sensitive information from prying eyes. Let's integrate encryption protocols seamlessly into our applications to ensure that user data remains confidential.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={a} alt="Cybersecurity Services" className="img-fluid" />
                    </div>
                </div>

                {/* Data Protection and Encryption Section */}
                <div className="row">
                    <div className="col-md-6">
                        <img src={b} alt="Data Protection and Encryption" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <h3>Vigilance Against Threats</h3>
                        <p>
                        The digital landscape is dynamic, with new threats emerging regularly. Staying vigilant and proactive is key. Regular security audits, threat modeling, and penetration testing can help identify vulnerabilities before they are exploited. By embracing a security-first mindset, we stay one step ahead of potential threats.
                        </p>
                    </div>
                </div>

                {/* Security Compliance Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Collaboration Across Teams</h3>
                        <p>
                        Security is not the sole responsibility of a security team; it's a collective effort. As developers, collaborating with security professionals, QA teams, and other stakeholders ensures a holistic approach to security. Knowledge sharing and cross-team communication are instrumental in building a robust defense against evolving threats.
                        </p>
                    </div>
                </div>

                {/* Threat Intelligence Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Let's remember that</h3>
                        <p>
                        In conclusion, our commitment to security is not just a checkbox on a list; it's a continuous journey that requires our unwavering attention. By integrating security into our development ethos, we not only protect our users and their data but also contribute to the overall resilience of the digital ecosystem.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* <img src="/images/threat-intelligence.jpg" alt="Threat Intelligence" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to fortify your security defenses? Let's discuss how our security solutions can provide a robust shield for your business.
                        </p>
                        <a href="contact1" className="btn btn-primary btn-lg">Enhance Security</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecuritySolutionsOffer;
