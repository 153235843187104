import React from 'react'
import { Container, Row, Col } from 'reactstrap';

const sectionsData = [
    {
        heading: 'Personal Information',
        content: [
            'Lorem ipsum dolor sit amet, consectetur',
            'Quidem error quae illo excepturi nostrum blanditiis laboriosam',
            'Molestias, eum nihil expedita dolorum odio dolorem',
            'Eum nihil expedita dolorum odio dolorem',
            'Explicabo rem illum magni perferendis',
        ],
    },
    {
        heading: 'Use of User Information',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.',
        ],
    },
    {
        heading: 'Disclosure of User Information',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem ullam nostrum dolor alias aspernatur nobis suscipit eaque cumque distinctio eos, beatae deserunt, nihil nam maiores vero, eius harum. Reprehenderit, aspernatur.',
            'Lorem ipsum dolor sit amet, consectetur',
            'Quidem error quae illo excepturi nostrum blanditiis laboriosam',
            'Molestias, eum nihil expedita dolorum odio dolorem',
            'Eum nihil expedita dolorum odio dolorem',
            'Explicabo rem illum magni perferendis',
        ],
    },
];


function Privacy() {
    return (
        <>
            <section>
                <Container>
                    <Row>
                        <Col lg="12" md="12">
                            <p>
                            Privacy Policy for EpicFinal DevOps Services

                            Last Updated: 15.11.2023
                            <br></br>
                            1. Overview

                            Thank you for choosing EpicFinal for your DevOps services. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when using our DevOps services.
                            </p>
                            <p>
                             2. Information We Collect<br></br>
                             2.1 Personal Information: We may collect personal information, including but not limited to names, email addresses, and contact details, when you register for our services or communicate with us.

                            2.2 Usage Data: We collect information about how our services are accessed and used. This may include IP addresses, device information, browser type, and other usage statistics.

                            3. How We Use Your Information

                            3.1 Service Delivery: We use your information to provide, maintain, and improve our DevOps services.

                            3.2 Communication: We may use your contact information to send you important updates, newsletters, or other information related to our services.
                            </p>
                            <p>
                                <br></br>4. Information Sharing and Disclosure  
                                4.1 Third-Party Services: We may share your information with third-party service providers who assist us in delivering our services.

                                4.2 Legal Requirements: We may disclose your information when required by law or in response to valid legal requests.
                            </p>
                            <p>5. Security

                            We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. but we are not responsible.</p>
                            <p>6. Your Choices<br></br>
                            You have the right to access, correct, or delete your personal information. Contact us at support@epicfinal.com to exercise these rights.</p>
                            <p>7. Changes to This Privacy Policy<br></br>
                            We may update our Privacy Policy from time to time. The latest version will be posted on our website with the effective date.</p>
                            <p>8. Contact Us</p><br></br>
                            If you have any questions or concerns about our Privacy Policy, please contact us at support@epicfinal.com.
                            {/* {sectionsData.map((section, index) => (
                                <React.Fragment key={index}>
                                    <h4 className="mt-5 text-primary">{section.heading}</h4>
                                    {section.content.map((item, i) => (
                                        <div className="d-flex align-items-center mb-3" key={i}>
                                            <div className="bg-light rounded p-1">
                                                <i className="las la-check" />
                                            </div>
                                            <p className="mb-0 ms-3">{item}</p>
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))} */}

                            <p className="mt-5 mb-0">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Privacy
