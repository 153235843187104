import React ,{ useState } from 'react'
//import { Route, Routes, useLocation, Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import Verify from '../../../../src/defaultPages/Verify/Verifycomponent.js';





function Signupcomponet() {


  const [formData, setFormData] = useState({
    email: '',
    passcode: '',
  });

  const [registrationStatus, setRegistrationStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const navigate = useNavigate(); // Access the history object


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Set loading state when the form is submitted
    setRegistrationStatus({ loading: true, success: false, error: null });



    // Make a POST request to your API endpoint with formData
    axios.post('http://127.0.0.1:5090/api/accounts/verify', formData)
    .then((response) => {
      if (response.status === 200 && response.data.message === "user not found" || response.data.message === "passcode not correct") {
        setRegistrationStatus({ loading: false, success: false, error: response.data.message });
        console.error('Verify failed:', response.data.message);
      } else if (response.status === 200){
       // Registration was successful
       setRegistrationStatus({ loading: false, success: true, error: null });
      // Redirect to the verification page
       console.log('Verifed successful, you can Login');
       // You can handle success actions here, such as redirection or displaying a success message.
      }
      else {
        // Registration failed
        setRegistrationStatus({ loading: false, success: false, error: response.data });
        console.error('Verify failed:', response.data);
        // Handle registration failure here, display an error message, etc.
      }
    })
    .catch((error) => {
      // Network error or other exceptions
      setRegistrationStatus({ loading: false, success: false, error: error.message });
      console.error('Error occurred:', error);
      // Handle network errors or other exceptions here.
    });
};



  return (
    <section className="register">
    <div className="row justify-content-center text-center row container col-lg-8 col-md-10 ms-auto me-auto ">
    <div className="row justify-content-center text-center">
      <div className="col-lg-8 col-md-12">
        <div className="mb-5">
        <h2><span className="font-w-4">Simple And</span> Easy To Sign Up</h2>
        <p className="lead">We use the latest technologies</p>
        <div className="form-group">
      <form onSubmit={handleSubmit} className="register">
        <div>
          <div className="messages"></div>
          <input className="form-control" 
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email"
          required
        />
        </div>
        <input className="form-control"
          type="password"
          name="passcode"
          value={formData.passcode}
          onChange={handleInputChange}
          placeholder="Verify Code"
          required
        />
        
        <div className="help-block with-errors"></div>
        <br></br>
        <button href="/" className="btn btn-primary" type="submit">Verify!</button>
     
        
      </form>
      </div>
      
     
      
      {/* Display registration status */}
      {registrationStatus.loading && <p style={{color:'orange'}}>Verifying .....</p>}
      {registrationStatus.success && <p style={{color:'green'}}>Verifed! now you can Login!</p>}
      {registrationStatus.error && <p>Error2: {registrationStatus.error}</p>}

      
        </div>
      </div>
      
    </div>
    
    </div>
    
    </section>
  );
}

export default Signupcomponet
