import React from 'react'
import Terms from '../../Componet/Terms'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import Dev1 from '../../Componet/Develoment/dev1'


function TermsConditions() {
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Utilities"} name={"Development Solution"} />
            <div class="page-content">
                <Dev1></Dev1>
                <NewsletterL1 />
            </div>
        </>
    )
}

export default TermsConditions
