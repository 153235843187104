import React from 'react';
import a from './img1.png'
import b from './img2.png'


const MSPServerSolutionsOffer = () => {
    return (
        <section className="msp-server-solutions-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>ServerCare  Plus</h2>
                        <p className="lead">
                            Unveil our all-encompassing Managed Server Services tailored for your IT infrastructure's reliability, security, and performance. With expert support and customized solutions, optimize your server operations and enjoy peace of mind with our proactive monitoring, maintenance, and patching services.
                        </p>
                    </div>
                </div>

                {/* Server Management Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Proactive Server Management</h3>
                        <p>
                            Experience comprehensive server management with 24/7 monitoring, maintenance, and timely patching to keep your servers performing at their peak. Our services are designed to preemptively address issues before they impact your operations, ensuring maximum uptime and efficiency.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* Optional image slot */}
                        <img src={a} alt="Custom BackUP" className="img-fluid" />
                    </div>
                </div>

                {/* Cloud Services Section */}
                <div className="row">
                    <div className="col-md-6">
                        {/* Optional image slot */}
                        <img src={b} alt="Custom BackUP" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <h3>Cloud Services with Continuous Monitoring</h3>
                        <p>
                            Embrace cloud computing with our managed cloud services, featuring ongoing monitoring and management. We simplify cloud migration and optimize your cloud infrastructure for security, performance, and cost efficiency, backed by robust monitoring and patch management strategies.
                        </p>
                    </div>
                </div>

                {/* Cybersecurity Solutions Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Comprehensive Cybersecurity Solutions</h3>
                        <p>
                            Fortify your defenses with our cybersecurity solutions, incorporating real-time monitoring and rapid patching to guard against threats. Our proactive approach ensures your data and infrastructure are protected against the latest vulnerabilities and cyberattacks.
                        </p>
                    </div>
                </div>

                {/* Data Backup and Recovery Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Reliable Data Backup and Recovery</h3>
                        <p>
                            Guarantee your data's integrity with our advanced data backup and swift recovery services. We implement a multi-layered approach including regular backups, monitoring for anomalies, and rapid restoration capabilities to mitigate data loss and downtime.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* Optional image slot */}
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Elevate your IT infrastructure with our managed services. Leverage our expertise in proactive monitoring, maintenance, and patching for unparalleled server performance and security.
                        </p>
                        <a href="contact360plus" className="btn btn-primary btn-lg">Contact now!</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MSPServerSolutionsOffer;
