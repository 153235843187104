import React, { useState } from 'react'
import { Collapse } from 'reactstrap';


function AboutFAQ() {
    const [openAccordion, setOpenAccordion] = useState(1);

    const toggleAccordion = (accordionIndex) => {
        setOpenAccordion(openAccordion === accordionIndex ? null : accordionIndex);
    };
    return (
        <>
            <section>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                            <img src={require("../../assets/images/about/04.png")} alt="Image1" className="img-fluid" />
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">
                            <div className="accordion" id="accordion">
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(1)}
                                            aria-expanded={openAccordion === 1}
                                            aria-controls="collapseOne"
                                        >
                                            What is Devops? ?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 1} aria-labelledby="headingOne" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        DevOps, short for Development and Operations, is a set of practices that brings together software development (Dev) and IT operations (Ops) to enhance collaboration and efficiency in the software development and delivery process. It emphasizes automation, continuous integration, continuous delivery, and a culture of collaboration to deliver high-quality software more rapidly and reliably. In essence, DevOps aims to break down silos between development and operations teams, fostering a streamlined and iterative approach to software development and deployment.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded mb-2">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(2)}
                                            aria-expanded={openAccordion === 2}
                                            aria-controls="collapseTwo"
                                        >
                                            What you offer ?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 2} aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                                As a DevOps service provider, we offer comprehensive solutions to streamline and enhance the software development and delivery process. Your services include:

                                                <h5>Collaboration Enhancement:</h5>

                                                Facilitating seamless collaboration between development and operations teams to improve communication and efficiency.
                                                Automation Implementation:

                                                Implementing automation tools and practices for tasks such as code deployment, testing, and infrastructure management to accelerate the development lifecycle.
                                                <h5>Continuous Integration and Delivery (CI/CD):</h5>

                                                Establishing CI/CD pipelines to enable the continuous integration of code changes and the automated delivery of software to production environments.
                                                <h5>Infrastructure as Code (IaC):</h5>

                                                Introducing IaC principles to automate and manage infrastructure through code, ensuring consistency and scalability.
                                                <h5>Monitoring and Analytics:</h5>

                                                Setting up robust monitoring and analytics solutions to track application performance, detect issues proactively, and gather insights for continuous improvement.
                                                <h5>Security Integration (DevSecOps):</h5>

                                                Integrating security practices throughout the development process to identify and address vulnerabilities early on, ensuring a secure software delivery.
                                                <h5>Consultation and Training:</h5>

                                                Providing consultation and training services to teams, fostering a DevOps culture, and ensuring the adoption of best practices.
                                                <h5>Customized Solutions:</h5>

                                                Tailoring DevOps solutions to meet the specific needs and goals, ensuring a personalized and effective approach to software development and operations.
                                                By offering these services, we empower organizations to deliver software faster, with higher quality, and in a more collaborative and efficient manner, ultimately contributing to their overall success.
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item rounded">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button border-0 mb-0 bg-transparent"
                                            color="link"
                                            onClick={() => toggleAccordion(3)}
                                            aria-expanded={openAccordion === 3}
                                            aria-controls="collapseThree"
                                        >
                                           For which clients DevOps solutions well-suited ?
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openAccordion === 3} aria-labelledby="headingThree" data-bs-parent="#accordion">
                                        <div className="accordion-body text-muted">
                                        DevOps solutions can benefit a wide range of clients across various industries. Here are some types of clients who can particularly benefit from DevOps services:
                                        <h5>Software Development Companies:</h5>
                                        DevOps helps software development companies streamline their development processes, improve collaboration between teams, and accelerate the delivery of high-quality software.
                                        <h5>Enterprise Organizations:</h5>
                                        Large enterprises with complex IT environments can leverage DevOps to enhance agility, automate operations, and ensure the rapid and reliable delivery of software.
                                        <h5>
                                        Startups and Small Businesses:
                                        </h5>
                                        DevOps is valuable for startups and small businesses aiming to optimize their development cycles, quickly release minimum viable products, and scale efficiently.
                                        <h5>E-commerce Platforms:</h5>
                                        E-commerce businesses benefit from DevOps by ensuring reliable and secure online platforms, handling high traffic, and implementing continuous improvements to user experiences.
                                        <h5>IT Service Providers:</h5>
                                        Companies providing IT services, including managed services and cloud solutions, can enhance their offerings by adopting DevOps practices to improve efficiency and service delivery.
                                        <h5>Healthcare and Finance:</h5>
                                        Industries with stringent regulatory requirements, such as healthcare and finance, can benefit from DevOps by ensuring compliance, security, and continuous delivery while maintaining a focus on quality.
                                        <h5>Government Organizations:</h5>
                                        Government agencies can adopt DevOps to modernize their IT systems, enhance collaboration between departments, and improve the delivery of citizen services.
                                        <h5>IoT (Internet of Things) Providers:</h5>
                                        Organizations dealing with IoT solutions can leverage DevOps to manage the complexities of interconnected devices, ensuring seamless integration and updates.
                                        In essence, any organization seeking to enhance collaboration, accelerate software delivery, improve quality, and respond effectively to market changes can find value in adopting DevOps practices.
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutFAQ
