import React from 'react'
import Terms from '../../Componet/Terms'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import DISASTER1 from '../../Componet/Disaster/DISASTER1'


function TermsConditions() {
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Utilities"} name={"Disaster Recovery"} />
            <div class="page-content">
                <DISASTER1></DISASTER1>
                <NewsletterL1 />
            </div>
        </>
    )
}

export default TermsConditions
