import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

const featureData = [
  { icon: 'las la-angle-right', text: 'Software Development' },
  { icon: 'las la-angle-right', text: 'Operations and Microservices' },
  { icon: 'las la-angle-right', text: 'Cyber Security Mindset' },
  { icon: 'las la-angle-right', text: 'Consulting ' },
  { icon: 'las la-angle-right', text: 'Public and Private Clouds' },
  { icon: 'las la-angle-right', text: 'Planing to Production & Beyond' },
  
];

function AboutL3() {
  return (
    <section className="py-4">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col xs="12" lg="5">
            <img src={require("../../assets/images/about/06.png")} alt="Image6" className="img-fluid" />
          </Col>
          <Col xs="12" lg="6" mt="5" mt-lg="0">
            <div className="mb-4">
              <h4><span className="font-w-4 d-block">Enabling a DevOps approach, we expertly handle the technological aspects of your idea,</span> promoting collaboration, automation, and continual enhancement for maximum efficiency and innovation. Our commitment extends from the project's inception through successful production and beyond, with vigilant monitoring for sustained excellence.</h4>
              <p className="lead mb-0">We're here to grant you the time to focus on what matters most to you. Let the technology be our responsibility, ensuring seamless operations while you invest your time where it's needed.</p>
            </div>
            <Row noGutters>
              <Col sm="6">
                {featureData.slice(0, 3).map((feature, index) => (
                  <div key={index} className="mb-4">
                    <div className="d-flex align-items-center">
                      <div>
                        <i className={feature.icon}></i>
                      </div>
                      <p className="mb-0 ms-3">{feature.text}</p>
                    </div>
                  </div>
                ))}
              </Col>
              <Col sm="5">
                {featureData.slice(3).map((feature, index) => (
                  <div key={index} className="mb-4">
                    <div className="d-flex align-items-center">
                      <div>
                        <i className={feature.icon}></i>
                      </div>
                      <p className="mb-0 ms-3">{feature.text}</p>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
            <Button href="/" color="outline-primary" className="mt-7">
              Learn More
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutL3;
