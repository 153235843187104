import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './FeatureL2.css'; // Assuming your CSS file is named FeatureL2.css
import a from './cloud-done.png'
import b from './TI-DONE.png'
import c from './COLLEGE-DONE.png'
import d from './Disaster.png'
import e from './Managed.png'
import f from './Monitoring.png'
import g from './automation.png'
import h from './IT.png'
import i from './GA.PNG'




const features = [
  { icon: 'flaticon-dashboard', background: "#ffccaa", title: "תכנון ומחקר", description: "מחקרים וניתוח נתונים תוך תכנון מושכל ואסטרטגי להצלחה המרבית שלנו" },
  { icon: 'flaticon-relationship', background: "#a2fadd", title: "פיתוח", description: "אנחנו מתמחים בהפיכת הרעיונות שלכם למציאות באמצעות טכנולוגיות מתקדמות" },
  { icon: 'flaticon-system', background: "#ffeadd", title: "תפעול", description: "אנחנו מנהלים את התפעול ואת הצמיחה של הרעיון שלכם, דואגים למסע חלק וביצועים גבוהים" },
  { icon: 'flaticon-solution', background: "#ff9b8e", title: "אינטגרציות", description: "חיבור לפעולות שלך על ידי אינטגרציה חלקה של מערכות שונות, יוצרים תהליך עבודה יעיל ומאורגן עבור העסק שלך" },
  { icon: 'flaticon-system', background: "#A2FADD", title: "אבטחה", description: "עם אבטחה בראש סדר העדיפויות שלנו, אנו מתכננים כל נקודת רקע עם שיקול" },
  { icon: 'flaticon-call-center-1', background: "#ffccaa", title: "ייעוץ ותמיכה", description: "אנחנו משמשים כשותפי ייעוץ ותמיכה, מציעים תומכים ומומחים כשאתם צריכים" },
];



function FeatureL2() { 
  return (
    <section>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8">
            <div className="mb-5">
              <h2 className="mb-0">
                <span className="font-w-4 d-block">ליווי פרויקטים טכנולוגיים מקצה-לקצה וגם מעבר</span>   
                  <span> </span>אנחנו מביאים קדמה של טכנולוגיה חדשנית גם לעסקים קטנים ובינוניים
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          {features.map((feature, index) => (
            <Col lg="4" md="6" key={index} className="mt-5">
              <div className="d-flex justify-content-between flex-lg-row-reverse">
                <div className="icon-spacing">
                  <div className="f-icon-s p-3 rounded" style={{ background: feature.background }}>
                    <i className={`flip-horizontal ${feature.icon}`}></i>
                  </div>
                </div>
                <div>
                  <h5 className="mb-2 text-end">{feature.title}</h5>
                  <p className="mb-0 text-end">{feature.description}</p>
                </div>
              </div>
            </Col>
          ))}

        </Row>
        <br></br>
        <br></br>
        <br></br>
        <hr className="feature-divider" />
        <Row>
          
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="font-w-4 d-block justify-content-center text-end">פתרונות ושירותים</h1>
        {/* Promoting Images Section */}
        <Row className="mt-5">
          <Col md={{ size: 4, offset: 0 }} className="text-center">
            <img src={c} alt="שירותי ענן לעסקים" className="img-fluid mb-3" />
          </Col>
          <Col md={{ size: 4 }} className="text-center">
            <img src={b} alt="תשתיות טכנולוגיות" className="img-fluid mb-3" />
          </Col>
          <Col md={{ size: 4 }} className="text-center">
            <img src={a} alt="תכנון ופיתוח מערכות" className="img-fluid mb-3" />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={{ size: 4, offset: 0 }} className="text-center">
            <img src={d} alt="שירותי התאוששות מאסון" className="img-fluid mb-3" />
          </Col>
          <Col md={{ size: 4 }} className="text-center">
            <img src={e} alt="ניהול שירותים" className="img-fluid mb-3" />
          </Col>
          <Col md={{ size: 4 }} className="text-center">
            <img src={f} alt="שירותי ניטור ובקרה" className="img-fluid mb-3" />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={{ size: 4, offset: 0 }} className="text-center">
            <img src={i} alt="פיתוח תוכנה SAAS " className="img-fluid mb-3" />
            <br/>
            <br/>
            <br/>
  
           
            <a href="Pricing-Calculator" class="stylish-button">המשך למחשבון עלות</a>
            <br/>
            <br/>
            <br/>
          </Col>
          <Col md={{ size: 4 }} className="text-center">
            <img src={h} alt="IT שירותי" className="img-fluid mb-3" />
          </Col>
          <Col md={{ size: 4 }} className="text-center">
            <img src={g} alt="אוטומציות / אוטומציות" className="img-fluid mb-3" />
            
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FeatureL2;
