import React from 'react';
import a from './img1.png'
import b from './img2.png'

const NOCServicesOffer = () => {
    return (
        <section className="noc-services-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Network Operations Center (NOC) Services</h2>
                        <p className="lead">
                            Discover our comprehensive range of NOC services designed to ensure the reliability, efficiency, and security of your network infrastructure. Our expert team is dedicated to providing 24/7 monitoring and management to keep your operations running smoothly.
                        </p>
                    </div>
                </div>

                {/* Monitoring and Management Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Monitoring and Management</h3>
                        <p>
                            Leverage our state-of-the-art monitoring and management solutions for real-time oversight of your network. We ensure optimal performance and quick resolution of issues, minimizing downtime and enhancing productivity.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* Placeholder for potential image */}
                        <img src={b} alt="Custom BackUP" className="img-fluid" />
                        {/* <img src="/images/monitoring-management.jpg" alt="Monitoring and Management" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Security and Compliance Section */}
                <div className="row">
                    <div className="col-md-6">
                        {/* Placeholder for potential image */}
                        <img src={a} alt="Custom BackUP" className="img-fluid" />   
                        {/* <img src="/images/security-compliance.jpg" alt="Security and Compliance" className="img-fluid" /> */}
                    </div>
                    <div className="col-md-6">
                        <h3>Security and Compliance</h3>
                        <p>
                            Secure your network against emerging threats with our comprehensive security services. We provide continuous security monitoring, threat detection, and compliance management to protect your critical data and assets.
                        </p>
                    </div>
                </div>

                {/* Support and Troubleshooting Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Support and Troubleshooting</h3>
                        <p>
                            Our expert NOC team offers round-the-clock support and rapid troubleshooting to address any network issues. Benefit from our extensive experience to ensure fast resolution times and maintain high service levels.
                        </p>
                    </div>
                </div>

                {/* Infrastructure Management Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Infrastructure Management</h3>
                        <p>
                            Optimize your network infrastructure with our management services. From configuration to upgrades, we handle all aspects of infrastructure management to improve efficiency and scalability.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* Placeholder for potential image */}
                        {/* <img src="/images/infrastructure-management.jpg" alt="Infrastructure Management" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to enhance your network performance with professional NOC services? Explore our solutions and take the first step towards operational excellence.
                        </p>
                        <a href="contact-us" className="btn btn-primary btn-lg">Contact Us Now!</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NOCServicesOffer;
