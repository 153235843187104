import React from 'react'
import Terms from '../../Componet/Terms'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import Noc1 from '../../Componet/NOC/NOC1'


function TermsConditions() {
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Utilities"} name={"NOC"} />
            <div class="page-content">
                <Noc1></Noc1>
                <NewsletterL1 />
            </div>
        </>
    )
}

export default TermsConditions
