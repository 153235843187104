import React from 'react';

const ConsultingSolutionsOffer = () => {
    return (
        <section className="consulting-solutions-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Strategic Consulting Solutions for Your Business Success</h2>
                        <p className="lead">
                            Explore our consulting solutions designed to empower your business with strategic insights, expert advice, and tailored guidance for sustainable growth and success.
                        </p>
                    </div>
                </div>

                {/* Strategic Planning Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Strategic Planning</h3>
                        <p>
                            Elevate your business strategy with our expert consultants. We assist in developing strategic plans, setting objectives, and creating a roadmap for achieving long-term success.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* <img src="/images/strategic-planning.jpg" alt="Strategic Planning" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Business Process Optimization Section */}
                <div className="row">
                    <div className="col-md-6">
                        {/* <img src="/images/business-optimization.jpg" alt="Business Process Optimization" className="img-fluid" /> */}
                    </div>
                    <div className="col-md-6">
                        <h3>Business Process Optimization</h3>
                        <p>
                            Streamline your operations with our business process optimization services. We analyze your workflows, identify inefficiencies, and recommend improvements to enhance productivity.
                        </p>
                    </div>
                </div>

                {/* IT Consulting Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>IT Consulting</h3>
                        <p>
                            Leverage technology for business growth with our IT consulting services. Our experts provide guidance on technology adoption, system integration, and digital transformation for a competitive edge.
                        </p>
                    </div>
                </div>

                {/* Organizational Development Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Organizational Development</h3>
                        <p>
                            Foster a culture of continuous improvement with our organizational development consulting. We work with your teams to enhance communication, collaboration, and overall performance.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* <img src="/images/organizational-development.jpg" alt="Organizational Development" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to unlock the full potential of your business? Let's start a conversation on how our consulting solutions can drive positive change.
                        </p>
                        <a href="contact1" className="btn btn-primary btn-lg">Consult with Us</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ConsultingSolutionsOffer;
