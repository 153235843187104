    import React from 'react';
    import WA from '../../../src/WA.jpg'


    function HerosectionL4() {
        return (
            <section className="custom-py-1 hero-shape3 col-9 overflow-hidden" style={{ marginTop:'-85px'}}>
                 
                <div className="container ">
                    
                    <div className="row align-items-center " >
                        
                        <div className="col-11 col-lg-3 col-xl-5 order-lg-2 mb-2 mb-lg-5 mt-n4" >
                            {/* Image */}
                            <img
                                src={require("../../assets/images/hero/02.png")}
                                className="img-fluid  mt-lg-10 mt-xl-0 "
                                alt="..."
                            />
                            
                        </div>
                        <div className="col-12 col-lg-9 col-xl-7 order-lg-1 mt-n3 " >
                            <h1 className="display-6 mb-5 font-w-7 mt-3">
                                <span style={{ color: "#4285F4" }}>DevOps </span>
                                <span style={{ color: "#4285F4" }}>Solutions </span>
                                {/* <span style={{ color: "#4285F4" }}></span> */}
                            </h1>
                            
                            <p className="lead text-muted mb-5 font-w-6 mt-n5 "><span style={{ color: "#34A853" }}> Security at the Core: Unifying Development and Operations.</span></p>                             
                                <img    
                                    src={require("../../cicd6-1.jpg")}
                                    className="img-fluid mt-3 d-none d-lg-block"
                                    alt="Large Screen Image"
                                />
                                <img
                                    src={require("../../cicd-small.jpg")}
                                    className="img-fluid mt-3 d-lg-none .custom-small-image"
                                    alt="Small Screen Image"
                                    style={{ maxWidth: '100%', height: '205 px',}}
                                    
                                />

                            {/* Buttons */}
                            <div className="btn btn-sm btn-primary text-start me-2 mt-3">
                                <a href="contact1" className="la la-phone me-2 ic-2x d-inline-block" style={{color: 'black'}}></a>
                                <div className="d-inline-block">
                                    <a href="contact1" style={{color: 'black'}}>Contact now</a>
                                </div>
                            </div>
                            <div className="btn btn-sm  btn-info text-start mt-3" >
                                <a href="contact1" className="la la-info me-1 ic-2x d-inline-block" style={{color: 'black'}}></a>
                                <div  className="d-inline-block">
                                    <a href="about-us" style={{color: 'black' }}>About us</a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    export default HerosectionL4;
