import React from 'react'
import Terms from '../../Componet/Terms'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import PricingCalculator from '../../Componet/Calculator/Claculator-Hebrew'
import BottumStyle from '../Utilities/style.css' // Make sure the path is correct


function TermsConditions() {
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Utilities"} name={"מחשבון עלות"} />
            <div class="page-content">
                <PricingCalculator></PricingCalculator>
                


                <div className="button-container">
                <a href="HE" class="stylish-button">המשך לאתר בעברית</a>
                <div className="banner-container">
                <div className="text-overlay">
                    <br/>
                    <h2>החל מ-₪49 לחודש</h2>
                    <h3>עכשיו אפשר לשלם בתשלומים</h3>
                    {/* <h3>[עד 36 תשלומים]</h3> */}
                    {/* <button className="cta-button">Explore Now</button> */}
                </div>
                </div>
                </div>



                <NewsletterL1 />
            </div>
        </>
    )
}

export default TermsConditions
