import React from 'react';
import a from './img1.png'
import b from './img2.png'

const DisasterRecoveryCloudBackup = () => {
    return (
        <section className="disaster-recovery-cloud-backup">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Disaster Recovery & Cloud Backup Solutions</h2>
                        <p className="lead">
                            Secure your data with our comprehensive disaster recovery and remote cloud backup solutions. Protect your business from data loss and ensure business continuity with state-of-the-art technology and scalable infrastructure.
                        </p>
                    </div>
                </div>

                {/* Cloud Backup Solutions Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Cloud Backup Solutions</h3>
                        <p>
                            Keep your data safe with our secure, automated cloud backup solutions. Our services provide regular, encrypted backups to the cloud, ensuring your data is protected against loss, theft, or damage.
                        </p>
                    </div>
                    <div className="col-md-6">
                    <img src={a} alt="Custom BackUP" className="img-fluid" />
                    </div>
                </div>

                {/* Disaster Recovery Planning Section */}
                <div className="row">
                    <div className="col-md-6">
                        {/* Image for Disaster Recovery Planning could go here if desired */}
                        <img src={b} alt="Custom BackUP" className="img-fluid" />
                        

                    </div>
                    <div className="col-md-6">
                        <h3>Disaster Recovery Planning</h3>
                        <p>
                            Prepare for the unexpected with our disaster recovery planning services. We help you develop a robust strategy to minimize downtime and maintain operations in the event of a disaster, ensuring rapid data recovery and system restoration.
                        </p>
                    </div>
                </div>

                {/* Data Protection and Security Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Data Protection and Security</h3>
                        <p>
                            Safeguard your critical data with our comprehensive protection and security measures. From end-to-end encryption to multi-factor authentication, we provide the tools you need to secure your data against unauthorized access and cyber threats.
                        </p>
                    </div>
                </div>

                {/* Business Continuity Solutions Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Business Continuity Solutions</h3>
                        <p>
                            Ensure your business remains operational during and after a disaster with our business continuity solutions. We offer strategies and technologies that support critical business functions, protecting your bottom line and customer trust.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* Image for Business Continuity could go here if desired */}
                        
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    
                    <div className="col-lg-8 text-center">
                        
                        <p className="lead">
                            Don't let a disaster put your operations at risk. Discover our disaster recovery and cloud backup solutions today and take the first step towards comprehensive data protection.
                        </p>
                        <a href="contact1" className="btn btn-primary btn-lg">Contact Us Now!</a>
                        
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DisasterRecoveryCloudBackup;
