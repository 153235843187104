import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const careerData = [
  {
    number: '01',
    title: 'Work From Home',
    description: 'Unlock a new era of productivity and work-life balance with our innovative work-from-home solutions. Embrace flexibility, boost efficiency, and enjoy the freedom to excel in your professional journey, all from the comfort of your home office.',
  },
  // {
  //   number: '02',
  //   title: 'Office An Great Location',
  //   description: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia Neque porro est.',
  // },
  // {
  //   number: '03',
  //   title: 'Shared Success',
  //   description: 'Officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et non recusandae.',
  // },
  // {
  //   number: '04',
  //   title: 'Medical insurance',
  //   description: 'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model infancy.',
  // },
  {
    number: '02',
    title: 'Student Success',
    description: 'Embrace a dynamic learning experience! We warmly welcome students to join our company, where innovative opportunities await. Gain practical skills, collaborate with seasoned professionals, and kickstart your career journey with us.',
  },
  {
    number: '03',
    title: 'Bonus Available',
    description: 'Rewarding excellence! Enjoy special bonuses as a token of appreciation for your outstanding work. Your dedication makes our success possible, and were thrilled to recognize and reward your contributions.',
  },
];

function AboutCareer() {
  return (
    <section>
      <Container>
        <Row>
          {careerData.map((item, index) => (
            <Col key={index} md="4">
              <h5>
                <span className="text-primary font-w-7">{item.number}.</span> {item.title}
              </h5>
              <p>{item.description}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default AboutCareer;
