import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const features = [
  {icon: 'flaticon-dashboard', background: "#ffccaa", title: "Plan And Research", description: "We'll collaborate with you to shape and research your ideas, ensuring a well-informed and strategic planning process." },
  {icon: 'flaticon-relationship', background: "#a2fadd", title: "Development", description: "We specialize in transforming your ideas into reality using cutting-edge technologies." },
  {icon: 'flaticon-system' ,background: "#ffeadd", title: "Operations", description: "We oversee both the operational intricacies and scalable growth of your idea, ensuring a seamless and high-performance journey." },
  {icon: 'flaticon-solution', background: "#ff9b8e", title: "Integrations", description: "We bring synergy to your operations by seamlessly integrating diverse systems, creating a cohesive and efficient workflow for your business." },
  {icon: 'flaticon-system ', background: "#A2FADD", title: "Security", description: "With security at the forefront of our approach, we design every aspect with vigilant consideration, embedding protective measures into the very fabric of your digital environment." },
  {icon: 'flaticon-call-center-1', background: "#ffccaa", title: "Consulting and Support", description: "We serve as your dependable support and consulting partner, offering expertise and assistance whenever you require guidance or strategic insights." },
];

function FeatureL2() {
  return (
    <section>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8">
            <div className="mb-5">
              <h2 className="mb-0">
                <span className="font-w-4 d-block">Your Full-Stack IT Outsourcing Partner</span> for your next creative journey.
                
              </h2>
              <br></br>
              <a href="HE" class="stylish-button">המשך לאתר בעברית</a>
            </div>
          </Col>
        </Row>
        <Row>
          {features.map((feature, index) => (
            <Col lg="4" md="6" key={index} className="mt-5">
              <div className="d-flex justify-content-between">
                <div className="me-3">
                  <div className="f-icon-s p-3 rounded" style={{ background: feature.background }}>
                    <i className={`${feature.icon}`}></i>
                    <img src={`../../assets/images/client/${index + 9}.png`} alt="" />
                  </div>
                </div>
                <div>
                  <h5 className="mb-2">{feature.title}</h5>
                  <p className="mb-0">{feature.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default FeatureL2;
