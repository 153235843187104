import React from 'react'


function AboutUsL2() {
  return (
    <div>
      <section>
  <div className="container">
    <div className="row align-items-center justify-content-between">
      <div className="col-12 col-lg-6 mb-5 mb-lg-0 order-lg-1">
        <img src={require("../../assets/images/about/04.png")} alt="Image4" className="img-fluid"/>
      </div>
      <div className="col-12 col-lg-6">
        <div className="mb-5">
          <h2><span className="font-w-4 d-block">Addressing your business challenges</span> through strategic planning and solutions.</h2>
          <p className="lead mb-0">We leverage the latest technologies to deliver solutions that enhance your experience and exceed expectations.</p>
        </div>
        <div>
          <div className="mb-3">
            <div className="d-flex align-items-start">
              <div className="me-3"> <span className="list-dot" data-bg-color="#009faa"></span>
              </div>
              <p className="mb-0">- Introducing a new era of serving enterprise technology to startups and SMBs.</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-start">
              <div className="me-3"> <span className="list-dot" data-bg-color="#009faa"></span>
              </div>
              <p className="mb-0">- bringing cutting-edge solutions tailored to your needs.</p>
            </div>
          </div>
          <div>
            
            <div className="d-flex align-items-start">
              <div className="me-3"> <span className="list-dot" data-bg-color="#A2FADD"></span>
              </div>
              <p className="mb-0">- ensuring technological advancement from end to end and beyond.</p>
              
            </div>
          </div>
          
        </div>
<a href="FAQ" className="btn btn-info mt-5">
                Learn More
              </a>
              
      </div>
      
    </div>
  </div>
  
</section>

    </div>
  )
}

export default AboutUsL2
