import React, { useState } from 'react';
import axios from 'axios';
import './Calculator.css'

const Claculator = () => {
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [result, setResult] = useState(null);
  const [appType, setAppType] = useState(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleAnswer = (value, checked) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [value]: { value, selected: checked }
    }));
  };

  const handleNext = () => {
    let totalQuestions = 0;
    if (appType === 'SaaS') {
      totalQuestions = saasQuestions.length;
    } else if (appType === 'LandingPage') {
      totalQuestions = landingPageQuestions.length;
    } else if (appType === 'DevOps') {
      totalQuestions = devOpsQuestions.length;
    }

    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      calculateResult();
    }
    if (window.innerWidth < 768) {
      // Scroll the page to the top
      window.scrollTo({ top: 900, behavior: 'smooth' });
    }
  };

  const handleAppTypeSelection = (type) => {
    setAppType(type);
    setCurrentQuestionIndex(0);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleNext();
    }
  };

  const calculateResult = () => {
    let totalDays = 0;
    const selectedOptions = [];

    let questionsArray;
    if (appType === 'SaaS') {
      questionsArray = saasQuestions;
    } else if (appType === 'LandingPage') {
      questionsArray = landingPageQuestions;
    } else if (appType === 'DevOps') {
      questionsArray = devOpsQuestions;
    }

    Object.values(answers).forEach(({ value, selected }) => {
      if (selected) {
        totalDays += parseInt(value.split('_')[0]);
        const question = questionsArray.find(question => question.options.find(option => option.value === value));
        const selectedOption = question.options.find(option => option.value === value);
        selectedOptions.push({ question: question.text, option: selectedOption.label });
      }
    });

    setResult(totalDays);

    axios.post('https://api.epicfinal.com/send-email', { result: totalDays, selectedOptions })
      .then(response => {
        console.log('Email sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  const handleSubmit = () => {
    axios.post('https://api.epicfinal.com/send-email-submit', {email, phoneNumber }) // Fixed: Added selectedOptions to the POST data
      .then(response => {
        console.log('Email sent successfully:', response.data);
        setSubmitted(true);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  }; 

  const saasQuestions = [
    {
      id: 0,
      text: 'פלטפורמה?',
      options: [
        { label: 'אפליקצית אינטרנט', value: '3_Web' },
        { label: 'אנדרויד', value: '5_Android' },
        { label: 'אייפון', value: '5_iOS' },
        { label: 'ווינדוס 10/11 ', value: '4_Windows Dekstop' }
      ]
    },
    {
      id: 1,
      text: 'גודל האפליקציה?',
      options: [
        { label: '(קטנה) הוכחת היתכנות / 3-4 תכונות', value: '10_small' },
        { label: '(בינונית) מוצר בר-קיימא / 5-9 תכונות', value: '30_medium' },
        { label: '(גדולה) מוצר סופי / 9-15 תכונות', value: '50_large' }
      ]
    },
    {
      id: 2,
      text: 'מורכבות של ממשק משתמש (UI)?',
      options: [
        { label: 'מינימאלי', value: '10_mvp' },
        { label: 'רגיל', value: '30_basic' },
        { label: 'מתקדם', value: '70_polished' }
      ]
    },
    {
      id: 3,
      text: 'משתמשים',
      options: [
        { label: 'הרשמה בְּאֶמְצָעוּת אימייל וסיסמא', value: '1_email_password' },
        { label: 'הרשמה בְּאֶמְצָעוּת פייסבוק', value: '2_facebook' },
        { label: 'הרשמה בְּאֶמְצָעוּת טוויטר', value: '2_twitter' },
        { label: 'הרשמה בְּאֶמְצָעוּת גוגל', value: '2_google' },
        { label: 'הרשמה בְּאֶמְצָעוּת לינקדין', value: '2_linkedin' },
        { label: 'הרשמה בְּאֶמְצָעוּת גיט-האב', value: '2_github' },
        { label: 'הזמנת משתמשים במייל', value: '2_invitation_emails' },
        { label: 'ריבוי דיירים? (Multi-tenant)', value: '3_multi_tenant' },
        { label: 'ריבוי דיירים: תת-שם מתחם', value: '4_subdomains' },
        { label: 'ריבוי דיירים: שם מתחם מותאם אישית', value: '4_custom_domains' }
      ]
    },
    {
      id: 4,
      text: 'תוכן',
      options: [
        { label: 'לוח בקרה', value: '5_dashboard' },
        { label: 'פיד פעילות', value: '4_activity_feed' },
        { label: 'העלאת קבצים', value: '4_file_uploading' },
        { label: 'פרופילי משתמש', value: '2_user_profiles' },
        { label: 'תזמון אימיילים', value: '2_transactional_emails' },
        { label: 'תגיות', value: '2_tags' },
        { label: 'דירוגים או ביקורות', value: '5_ratings_reviews' },
        { label: 'עיבוד אודיו/וידאו', value: '5_audio_video' },
        { label: 'חיפוש טקסט חופשי', value: '4_text_searching' }
      ]
    },
    {
      id: 5,
      text: 'מיקום ויומן',
      options: [
        { label: 'יומן', value: '7_calendaring' },
        { label: 'תצוגת נתוני מפה / מיקום גאוגרפי', value: '3_map_data' },
        { label: 'תצוגת סימני מפה/אזורים מותאמים אישית', value: '3_custom_map' },
        { label: 'הזמנות', value: '8_bookings' }
            ]
    },
    {
      id: 6,
      text: 'פעולות בין משתמשים',
      options: [
        { label: 'הודעות', value: '6_messaging' },
        { label: 'פורומים או תגובות', value: '5_forums_commenting' },
        { label: 'שיתוף חברתי', value: '2_social_sharing' },
      ]
    },
    
{
    text: 'חיוב ומסחר אלקטרוני',
    options: [
    { label: 'תוכניות מנויים', value: '8_subscription_plans' },
    { label: 'עיבוד תשלומים', value: '5_payment_processing' },
    { label: 'עגלת קניות', value: '8_shopping_cart' },
    { label: 'זירת מסחר', value: '12_user_marketplace' },
    { label: 'ניהול מוצרים', value: '4_product_management' }
    ]
    },
    {
        id: 8,
        text: 'ניתוחים, משוב וניהול',
        options: [
        { label: 'אינטגרציה עם מערכת ניהול תוכן (CMS)', value: '7_cms_integration' },
        { label: 'דפי ניהול משתמשים', value: '4_user_admin_pages' },
        { label: 'הרשאות /  מערכת אישור תוכן', value: '4_moderation_content_approval' },
        { label: 'ניתוח שימוש', value: '3_usage_analytics' },
        { label: 'דיווחי קריסה', value: '1_crash_reporting' },
        { label: 'מעקב אחר ביצועים', value: '1_performance_monitoring' },
        { label: 'תמיכה בריבוי שפות', value: '4_multilingual_support' }
        ]
        },
    {
      id: 9,
      text: 'אינטגרציה עם צד שלישי (API)',
      options: [
        { label: 'התחברות לשירותי צד שלישי אחד או יותר', value: '6_third_party_services' },
        { label: 'פיתוח אינטגרציה כדי שאחרים יוכלו להתממשק', value: '8_api_integration' },
        { label: 'הודעות SMS', value: '4_sms_messaging' },
        { label: 'הסתרת מספרי טלפון', value: '4_phone_number_masking' }
      ]
    },
    {
      id: 10,
      text: 'אבטחה',
      options: [
        { label: 'אימות דו-שלבי', value: '5_two_factor_auth' }
    ]
    }
  ];

  const landingPageQuestions = [
    {
      id: 0,
      text: 'המטרה העיקרית של האתר?',
      options: [
        { label: 'יצירת לידים', value: '0_Lead_Generation' },
        { label: 'קידום מוצר', value: '0_Product_Promotion' },
        { label: 'רישום לאירוע', value: '0_Event_Registration' },
        { label: 'הכרת מותג', value: '0_Brand_Awareness' },
        { label: 'אחר', value: '0_Other_Goal' }
      ]
    },
    {
      id: 1,
      text: 'סגנון עיצוב מועדף?',
      options: [
        { label: 'מינימליסטי', value: '0_Minimalist' },
        { label: 'מודרני', value: '0_Modern' },
        { label: 'עסקי', value: '0_Corporate' },
        { label: 'אחר', value: '0_Other_Style' }
      ]
    },
    {
      id: 2,
      text: 'קהל מטרה?',
      options: [
    
        { label: 'צרכני (B2C)', value: '0_Consumer_B2C' },
        { label: 'עסקי (B2B)', value: '0_Business_B2B' },
        { label: 'שניהם', value: '0_Both_Audiences' }

      ]
    },
    {
      id: 3,
      text: 'התממשקות עם רשת חברתית?',
      options: [
        { label: 'כן', value: '0_Social_Media_Yes' },
        { label: 'לא', value: '0_Social_Media_No' }
      ]
    },
    {
      id: 4,
      text: 'טופס יצירת קשר?',
      options: [
        { label: 'כן', value: '1_Contact_Form_Yes' },
        { label: 'לא', value: '0_Contact_Form_No' }
      ]
    },
    {
      id: 5,
      text: 'פעולת קריאה מועדפת (CTA)?',
      options: [
      
            { label: 'צור קשר', value: '0_Contact_Us' },
            { label: 'הרשם', value: '0_Subscribe' },
            { label: 'הורד', value: '1_Download' },
            { label: 'מידע נוסף', value: '0_Learn_More' },
            { label: 'אחר', value: '0_Other_CTA' }
      ]
    },
    {
      id: 6,
      text: 'עיצוב רספונסיבי?',
      options: [
        { label: 'כן', value: '1_Responsive_Yes' },
        { label: 'לא', value: '0_Responsive_No' }
      ]
    },
    {
      id: 7,
      text: 'נוסחת צבעים מועדפת?',
      options: [
        { label: 'כחול', value: '0_Color_Blue1' },
        { label: 'ירוק', value: '0_Color_Green2' },
        { label: 'אדום', value: '0_Color_Red3' },
        { label: 'מייפל', value: '0_Color_Yellow4' },
        { label: 'כתום', value: '0_Color_Yellow5' },
        { label: 'כחול שמיים', value: '0_Color_Yellow6' },
        { label: 'אחר', value: '0_Other_Color9' }
      ]
    },
    {
      id: 8,
      text: 'תוכן מוכן ? (קופירייטינג) ',
      options: [
        { label: 'כן', value: '0_Content_Yes' },
        { label: 'לא', value: '1_Content_No' }
      ]
    },
    {
      id: 9,
      text: 'תמונות וגראפיקה?',
      options: [
        { label: 'כן', value: '0_Images_Yes' },
        { label: 'לא', value: '0_Images_No' }
      ]
    },
    {
      id: 10,
      text: 'מספר עמודים?',
      options: [
        { label: '1', value: '1_Images_No1' },
        { label: '2', value: '1_Images_No2' },
        { label: '3', value: '1_Images_No3' },
        { label: '4', value: '2_Images_No4' },
        { label: '5', value: '2_Images_No5' },
        { label: '6', value: '2_Images_No6' },
        { label: 'מעל 6', value: '3_Images_No7' },
      ]
    },
    {
      id: 10,
      text: 'יש צורך במערכת ניהול תוכן?',
      options: [
        { label: 'כן', value: '1_Images_No' },
        { label: 'לא', value: '0_Images_No' },
     
      ]
    },
    {
      text: 'חיוב ומסחר אלקטרוני',
      options: [
      { label: 'תוכניות מנויים', value: '8_subscription_plans' },
      { label: 'עיבוד תשלומים', value: '5_payment_processing' },
      { label: 'עגלת קניות', value: '8_shopping_cart' },
      { label: 'זירת מסחר', value: '12_user_marketplace' },
      { label: 'ניהול מוצרים', value: '4_product_management' }
      ]
      },
  ];

  const devOpsQuestions = [
    {
      id: 0,
      text: 'ספק ענן?',
      options: [
        { label: 'אמזון AWS', value: '3_aws' },
        { label: 'גוגל GCP', value: '3_gcp' },
        { label: 'מייקרוסופט Azure', value: '5_azure' },
        { label: 'אחסון עצמאי', value: '3_other' },
        { label: 'אין לי תשתית', value: '3_other1' }
      ]
    },
    {
      id: 0,
      text: 'מעוניין בהקמה של ענן פרטי?',
      options: [
        { label: 'כן', value: '14_yescloud' },
        { label: 'לא', value: '0_nocloud' },
      ]
    },
    {
      id: 1,
      text: 'קונטיינרים ואורקסטרציה?',
      options: [
        { label: 'דוקר', value: '3_docker' },
        { label: 'קוברנטיס', value: '15_kubernetes' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 2,
      text: 'הקמת תשתית פיתוח (CICD)?',
      options: [
        { label: 'כן', value: '12_yes' },
        { label: 'לא', value: '0_no' }
      ]
    },
    {
        id: 2,
        text: 'תשתית כקוד?',
        options: [
          { label: 'כן', value: '20_yes' },
          { label: 'לא', value: '0_no' }
        ]
      },
    {
      id: 3,
      text: 'הקמת מערכת ניטור?',
      options: [
        { label: 'כן', value: '8_prometheus_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'התאוששות מאסון וגיבויים מרוחקים?',
      options: [
        { label: 'כן', value: '10_prometheus_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'פתרון אבטחת מידע?',
      options: [
        { label: 'יש לי צורך', value: '1_backup_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'אוטומציה?',
      options: [
        { label: 'כן', value: '6_auth_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'אינטגרציה?',
      options: [
        { label: 'כן', value: '7_in_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'הכנה לזמינות מלאה או לשרידות גבוהה?',
      options: [
        { label: 'כן', value: '10_ha_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'צוות תגובה?',
      options: [
        { label: 'כן', value: '0_ir_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'הדרכות ?',
      options: [
        { label: 'כן', value: '10_Training_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'שירות מנוהל ?',
      options: [
        { label: 'כן', value: '0_Ma_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'מחקר ?',
      options: [
        { label: 'כן', value: '0_Re_grafana' },
        { label: 'לא', value: '0_other' }
      ]
    },
    {
      id: 3,
      text: 'יעוץ ?',
      options: [
        { label: '1 שעה ', value: '1_Re_grafana1' },
        { label: '2 שעות', value: '1_othe1r' },
        { label: '3 שעות', value: '1_other2' },
        { label: '4 שעות', value: '1_other3' },
        { label: '5 שעות', value: '1_other4' },
        { label: '6 שעות', value: '1_other5' },
        { label: '7+ שעות יעוץ ', value: '0_other6' },
        
      ]
    },
  ];

  return (
<section className='calculator' style={{ direction: 'rtl' }}>
    <div className="container">
        <div className="row justify-content-center">
            <h1 className="header">זמן פיתוח - מחשבון עלות</h1>
            {appType === null ? (
                <div>
                    <h2 className="question">איזה סוג של פרויקט אתה רוצה לבנות?</h2>
                    <div className="options-container">
                        <button className="button" onClick={() => handleAppTypeSelection('SaaS')}>אפליקציה בהתאמה אישית (SaaS)</button>
                        <button className="button" onClick={() => handleAppTypeSelection('LandingPage')}>חנות אינטרנטית / דף נחיתה / בלוג</button>
                        <button className="button" onClick={() => handleAppTypeSelection('DevOps')}>תפעול, אבטחת מידע ושרתים</button>
                    </div>
                </div>
            ) : (
                result === null ? (
                    <div>
                        <h2 className="question">{appType === 'SaaS' ? saasQuestions[currentQuestionIndex].text : (appType === 'LandingPage' ? landingPageQuestions[currentQuestionIndex].text : devOpsQuestions[currentQuestionIndex].text)}</h2>
                        {appType === 'SaaS' ? (
                            <div className="options-container">
                                {saasQuestions[currentQuestionIndex].options.map(option => (
                                    <label
                                        key={option.value}
                                        className={`option-label ${answers[option.value] && answers[option.value].selected ? 'selected' : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{margin:'5px'}}
                                            checked={answers[option.value] ? answers[option.value].selected : false}
                                            onChange={e => handleAnswer(option.value, e.target.checked)}
                                            className="option-checkbox"
                                        />
                                        <span className="checkbox"></span>
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                        ) : (appType === 'LandingPage' ? (
                            <div className="options-container">
                                {landingPageQuestions[currentQuestionIndex].options.map(option => (
                                    <label
                                        key={option.value}
                                        className={`option-label ${answers[option.value] && answers[option.value].selected ? 'selected' : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{margin:'5px'}}
                                            checked={answers[option.value] ? answers[option.value].selected : false}
                                            onChange={e => handleAnswer(option.value, e.target.checked)}
                                            className="option-checkbox"
                                        />
                                        <span className="checkbox"></span>
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                        ) : (
                            <div className="options-container">
                                {devOpsQuestions[currentQuestionIndex].options.map(option => (
                                    <label
                                        key={option.value}
                                        className={`option-label ${answers[option.value] && answers[option.value].selected ? 'selected' : ''}`}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{margin:'5px'}}
                                            checked={answers[option.value] ? answers[option.value].selected : false}
                                            onChange={e => handleAnswer(option.value, e.target.checked)}
                                            className="option-checkbox"
                                        />
                                        <span className="checkbox"></span>
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                        ))}
                        <div className="button-group">
                            <button
                                className="button"
                                onClick={handleNext}
                                onKeyPress={handleKeyPress}
                            >
                                הבא
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2>זמן פיתוח משוער: {result} ימים</h2>
                        {!submitted && result !== null && (
                            <div className="contact-form" style={{ direction: "rtl",margin:'auto'}} >
                                <h2>קבל הצעת מחיר עוד היום על ידי השלמת החישוב  - <span style={{color:"#34495E"}}>ללא התחייבות</span></h2>
                                
                                <input
                                    type="email"
                                    placeholder="הזן את כתובת האימייל שלך"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ direction: "rtl" }} // Added this line
                                />
                                <input
                                    type="tel"
                                    placeholder="הזן את מספר הטלפון שלך"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    style={{ direction: "rtl" }} // Added this line
                                />
                                <button onClick={handleSubmit}>השלם</button>
                            </div>
                        )}
                        {submitted && (
                            <div>
                                <h2>{result === null ? "ניצור קשר בקרוב" : "תודה!"}</h2>
                            </div>
                        )}
                    </div>
                )
            )}
        </div>
    </div>
</section>


  );
};

export default Claculator;