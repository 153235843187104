const blogList = [
  {
    id: 1,
    image: 'assets/images/blog/01.jpg',
    date: '15 Nov',
    category: 'Landing',
    title: 'Welcome, EpicFinal is Running now.',
    author: 'Founder',
    views: 275, 
    comments: 1,
  },
  // {
  //   id: 2,
  //   image: 'assets/images/blog/02.jpg',
  //   date: '05 Sep',
  //   category: 'Software',
  //   title: 'The evolution of landing page 1111',
  //   author: 'Admin',
  //   views: 275,
  //   comments: 300,
  // },
  // {
  //   id: 3,
  //   image: 'assets/images/blog/03.jpg',
  //   date: '05 Sep',
  //   category: 'Landing',
  //   title: 'How to growth business with EPICFINAL',
  //   author: 'Admin',
  //   views: 275,
  //   comments: 300,
  // },
  // {
  //   id: 4,
  //   image: 'assets/images/blog/01.jpg',
  //   date: '05 Sep',
  //   category: 'Sass',
  //   title: 'EPICFINAL trending landing page 20201',
  //   author: 'Admin',
  //   views: 275,
  //   comments: 300,
  // },
  // {
  //   id: 5,
  //   image: 'assets/images/blog/02.jpg',
  //   date: '05 Sep',
  //   category: 'Software',
  //   title: 'The evolution of landing page   ',
  //   author: 'Admin',
  //   views: 275,
  //   comments: 300,
  // },
  
];
export default blogList;