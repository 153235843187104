import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';


function Logincomponet() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://127.0.0.1:5090/api/accounts/login', formData);

      if (response.status === 200) {
        const token = response.data.token;

        //console.log('API Response:', response);
        //console.log('Token:', token);

        // Store the token in local storage
        //localStorage.setItem('token', token);
        //sessionStorage.setItem('token', token);
        Cookies.set('token', token, { expires: 7, path: '/' });
        // Log the token after setting it
        //console.log('localStorage:', localStorage.getItem('token'));
        console.log('Redirecting to dashboard...');
        window.location.href = 'http://127.0.0.1:3000';

        // Redirect to the desired route or handle navigation
        handleLoginSuccess();

        console.log('Login successful!');
        // You can handle success actions here, such as redirection or displaying a success message.
      } else {
        // Handle other status codes or error cases
        console.error('Login failed:', response.data);
        setErrorMessage('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setErrorMessage('An error occurred during login. Please try again.');
    }
  };

  const handleLoginSuccess = () => {
    console.log('Redirecting to dashboard...');

    // setTimeout(() => {
    //   console.log('Redirecting to dashboard...');
    //   window.location.href = 'http://127.0.0.1:3001';
    // }, 1500); // 1 second delay (adjust as needed)

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <div>
        <div>
          <section>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7 col-12">
                  <img className="img-fluid" src={require("../../../assets/images/login.png")} alt="" />
                </div>
                <div className="col-lg-5 col-12">
                  <div>
                    <h2 className="mb-3">Sign In</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="messages">
                        {errorMessage && (
                          <div className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          id="form_name"
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          data-error="Email is required."
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="form-group">
                        <input
                          id="form_password"
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          required
                          data-error="Password is required."
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                      <div className="form-group mt-4 mb-5">
                        <button type="submit" className="btn btn-primary">
                          Login Now
                        </button>
                      </div>
                    </form>
                    <div className="d-flex align-items-center mt-4">
                      <span className="text-muted me-1">
                        Don't have an account?
                      </span>
                      <Link to="/sign_up">Sign Up</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Logincomponet;
