import React ,{ useState } from 'react'
//import { Route, Routes, useLocation, Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import Verify from '../../../../src/defaultPages/Verify/Verifycomponent.js';




function Signupcomponet({ }) {


  const [formData, setFormData] = useState({
    email: '',
    password: '',
    mobile: '',
    firstName: '',
    lastName: '',
  });

  const [registrationStatus, setRegistrationStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const navigate = useNavigate(); // Access the history object


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    


    // Set loading state when the form is submitted
    setRegistrationStatus({ loading: true, success: false, error: null });



    // Make a POST request to your API endpoint with formData
    axios.post('http://127.0.0.1:5090/api/accounts/createAccount', formData)
    .then((response) => {
      if (response.status === 200 && response.data.message === "User is already exist") {
        setRegistrationStatus({ loading: false, success: false, error: response.data.message });
        console.error('Registration failed:', response.data.message);
      } else if (response.status === 200){
       // Registration was successful
       setRegistrationStatus({ loading: false, success: true, error: null });
       navigate('/verify');
       console.log('Registration successful!');
       // You can handle success actions here, such as redirection or displaying a success message.
      }
      else {
        // Registration failed
        setRegistrationStatus({ loading: false, success: false, error: response.data });
        console.error('Registration failed:', response.data);
        // Handle registration failure here, display an error message, etc.
      }
    })
    .catch((error) => {
      // Network error or other exceptions
      setRegistrationStatus({ loading: false, success: false, error: error.message });
      console.error('Error occurred:', error);
      // Handle network errors or other exceptions here.
    });
};



  return (
    <section className="register">
    <div className="row justify-content-center text-center row container col-lg-8 col-md-10 ms-auto me-auto ">
    <div className="row justify-content-center text-center">
      <div className="col-lg-8 col-md-12">
        <div className="mb-5">
        <h2><span className="font-w-4">Simple And</span> Easy To Sign Up</h2>
        <p className="lead">We use the latest technologies</p>
        <div className="form-group">
      <form onSubmit={handleSubmit} className="register">
        <div>
          <div className="messages"></div>
          <input className="form-control" 
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email"
          required
        />
        </div>
        <input className="form-control"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          placeholder="Password"
          required
        />
        <div className="help-block with-errors"></div>
        <input className="form-control"
          type="tel"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
          placeholder="Mobile"
        />
        <div className="help-block with-errors"></div>
        <input className="form-control"
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder="First Name"
        />
        <div className="help-block with-errors"></div>
        <input className="form-control"
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Last Name"
        />
         <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="remember-checkbox clearfix mb-4">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input float-none" id="customCheck1" />
                          <label className="form-check-label" for="customCheck1">I agree to the term of use and privacy policy</label>
                          </div>
                            </div>
                              </div>
                               </div>
        <div className="help-block with-errors"></div>
        <button href="/" className="btn btn-primary" type="submit">Register</button>
     
        
      </form>
      </div>
      
     
      
      {/* Display registration status */}
      {registrationStatus.loading && <p>Registering user...</p>}
      {registrationStatus.success && <p>Registration successful!</p>}
      {registrationStatus.error && <p>Error2: {registrationStatus.error}</p>}

      
        </div>
      </div>
      
    </div>
    
    </div>
    
    </section>
  );
}

export default Signupcomponet
