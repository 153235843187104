import React from 'react';
import a from './img1.png'; // Assuming this image relates to custom cloud solutions
import b from './img2.png'; // Assuming this image showcases security features
import c from './img3.png'; // Assuming this image showcases security features


const PrivateCloudSolutionsOffer = () => {
    return (
        <section className="private-cloud-solutions-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Private Cloud</h2>
                        <p className="lead">
                            Explore our tailored Private Cloud solutions designed for small to medium businesses, emphasizing cost efficiency, scalability, and high availability. Our dedicated team ensures your private cloud infrastructure meets the specific needs of your business, providing 24/7 support and management.
                        </p>
                    </div>
                </div>

                {/* Customization and Scalability Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Customization and Scalability</h3>
                        <p>
                            Benefit from fully customizable cloud solutions that grow with your business. Our scalable private cloud infrastructure adapts to your changing needs, ensuring you only pay for what you use.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={a} alt="Customizable Private Cloud Solutions" className="img-fluid" />
                    </div>
                </div>

                {/* Security and Compliance Section */}
                <div className="row">
                    <div className="col-md-6">
                            <img src={b} alt="Secure Private Cloud" className="img-fluid" />   
                    </div>
                    <div className="col-md-6">
                        <h3>Security and Compliance</h3>
                        <p>
                            Our private cloud solutions come with enhanced security features and compliance controls, safeguarding your data against threats while adhering to industry standards and regulations.
                        </p>
                    </div>
                </div>

                {/* High Availability and Disaster Recovery Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>High Availability and Disaster Recovery</h3>
                        <p>
                            Ensure your business continuity with our high-availability cloud infrastructure, designed for maximum uptime. Our robust disaster recovery plans provide peace of mind, keeping your operations resilient in any scenario.
                        </p>
                    </div>
                </div>

                {/* Support and Management Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Dedicated Support and Management</h3>
                        <p>
                            Our expert team provides round-the-clock support and comprehensive management of your private cloud infrastructure, allowing you to focus on your core business activities.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* Potential place for an image illustrating support or management */}
                        <img src={c} alt="Secure Private Cloud" className="img-fluid" />
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to transform your business with a dedicated Private Cloud solution? Discover how our tailored services can make a difference for your business today.
                        </p>
                        <a href="contact-us" className="btn btn-primary btn-lg">Get Started Now</a>
                          

                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivateCloudSolutionsOffer;
