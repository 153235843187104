import React from 'react';
import './ITOperationsOffer.css'; // Import your CSS file
import a from './1.png';
import b from './2.png';
import c from './3.png';

const ITOperationsOffer = () => {
    return (
        <section className="it-operations-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="offer-title">Elevate Your IT Operations with Comprehensive Solutions</h2>
                        <p className="lead offer-description">
                            Explore our range of IT Operations services designed to optimize your infrastructure, enhance system management, and ensure seamless operations for your business.
                        </p>
                    </div>
                </div>

                {/* Infrastructure Optimization Section */}
                <div className="row infrastructure-section">
                    <div className="col-md-6">
                        <h3>Infrastructure Optimization</h3>
                        <p>
                            Streamline your IT infrastructure for optimal performance and efficiency. Our experts assess, plan, and implement solutions to enhance the reliability and scalability of your systems.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={a} alt="Infrastructure Optimization" className="img-fluid" />
                    </div>
                </div>

                {/* System Management Section */}
                <div className="row system-management-section">
                    <div className="col-md-6">
                        <img src={b} alt="System Management" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <h3>System Management</h3>
                        <p>
                            Ensure the seamless operation of your systems with our robust system management solutions. From monitoring to troubleshooting, we've got the tools and expertise to keep your systems running smoothly.
                        </p>
                    </div>
                </div>

                {/* IT Operations Tools Section */}
                <div className="row tools-section">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Our IT Operations Tools</h3>
                        <p>
                            We leverage a suite of cutting-edge tools to optimize and manage your IT operations effectively. Some of the tools in our arsenal include:
                        </p>
                        <ul className="list-unstyled">
                            <li>Monitoring and Alerting Systems</li>
                            <li>Configuration Management Tools</li>
                            <li>IT Service Management (ITSM) Platforms</li>
                            <li>Automation and Orchestration Solutions</li>
                            <li>Network Performance Tools</li>
                        </ul>
                    </div>
                </div>

                {/* Cloud Operations Section */}
                <div className="row cloud-operations-section">
                    <div className="col-md-6">
                        <h3>Cloud Operations</h3>
                        <p>
                            Embrace the power of the cloud with our Cloud Operations services. Whether you're migrating to the cloud or optimizing your existing cloud infrastructure, we ensure a secure and efficient transition.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={c} alt="Cloud Operations" className="img-fluid" />
                    </div>
                </div>
                    <br />
                    <br />
                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to enhance your IT operations? Let's discuss how our solutions can bring efficiency and reliability to your business.
                        </p>
                        <a href="contact1" className="btn btn-primary btn-lg cta-btn">Connect with Us</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ITOperationsOffer;
