import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const sectionsData = [
    {
        heading: 'epicfinal, DevOps Services Terms and Conditions. 1. Description of Service',
        content: [
            ' 1. Scope of Services, 1.1 epicfinal agrees to provide DevOps services to the client as outlined in the agreed-upon statement of work. 1.2 The client acknowledges that the nature of DevOps involves continuous integration and deployment processes, and epicfinal will make every effort to ensure seamless operations.',
        ],
    },
    {
        heading: ' 2. Responsibilities',
        content: [
            '2.1 The client agrees to provide necessary access, information, and cooperation to facilitate the DevOps process. 2.2 epicfinal will exercise due diligence in implementing DevOps practices but does not guarantee absolute error-free performance.',
        ],
    },
    {
        heading: ' 3. Security and Data Protection',
        content: [
            '3.1 epicfinal will implement industry-standard security measures to protect client data. 3.2 The client is responsible for ensuring the security of their systems, applications, and data.',
        ],
    },
    {
        heading: '4. Continuous Improvement   ',
        content: [
            '4.1 epicfinal will work towards continuous improvement of DevOps processes to enhance efficiency and reduce risks.',
        ],
        // checkList: [
        //     'Lorem ipsum dolor sit amet, consectetur',
        //     'Quidem error quae illo excepturi nostrum blanditiis laboriosam',
        //     'Molestias, eum nihil expedita dolorum odio dolorem',
        //     'Eum nihil expedita dolorum odio dolorem',
        //     'Explicabo rem illum magni perferendis',
        // ],
    },
    {
        heading: ' 5. Limitation of Liability',
        content: [
            '5.1 epicfinal shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the DevOps services. 5.2 The client acknowledges that the DevOps processes involve inherent risks, and epicfinal shall not be held liable for any disruptions or loss of data.',
        ],
    },
    {
        heading: ' 5. 6. Termination',
        content: [
            '6.1 Either party may terminate the agreement with written notice if the other party breaches any material terms. 6.2 Upon termination, the client shall pay for any services rendered up to the termination date.'
        ],
        
    },
    {
        heading: ' 7. 7. Confidentiality ',
        content: [
            '7.1 Both parties agree to maintain the confidentiality of proprietary information and trade secrets disclosed during the course of the engagement. ',
        ],
    },
    {
        heading: ' 8. Governing Law ',
        content: [
            '8.1 This agreement shall be governed by and construed in accordance with the state of israel laws ',
        ],
    },
    {
        heading: ' 10. Amendments   ',
        content: [
            ' 10.1 Any amendments to this agreement must be made in writing and agreed upon by both parties.  By agreeing to these terms, the client acknowledges and accepts the risks associated with DevOps processes. ',
        ],
    },
];

const Terms = () => {
    return (
        <>
            <section>
                <Container>
                    <Row>
                        <Col lg="12" md="12">
                            {sectionsData.map((section, index) => (
                                <React.Fragment key={index}>
                                    <h4 className="text-primary">{section.heading}</h4>
                                    {section.content.map((paragraph, pIndex) => (
                                        <p key={pIndex} className="mb-3">
                                            {paragraph}
                                        </p>
                                    ))}
                                    {section.checkList && section.checkList.map((item, iIndex) => (
                                        <div className="d-flex align-items-center mb-3" key={iIndex}>
                                            <div className="bg-light rounded p-1">
                                                <i className="las la-check" />
                                            </div>
                                            <p className="mb-0 ms-3">{item}</p>
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}
                            {/* <a className="btn btn-primary me-1" href="/">
                                Accept
                            </a>
                            <a className="btn btn-outline-primary" href="/">
                                Close
                            </a> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Terms;
