import React from 'react'
import Herosection4 from '../Componet/Herosection/HerosectionL4'
import Featurestart2 from '../Componet/Feature/FeatureL2'
import AboutL2 from '../Componet/About/AboutUsL2'
import Multisecstart from '../Componet/Multisec/MultisecsL1'
// import TestimonialL1 from '../Componet/Testimonial/TestimonialL4'
import Dev1 from  '../Componet/Develoment/dev1'
import Blog from '../Componet/Blog/BlogL3'
import Newsletter from '../Componet/Newsletter/NewsletterL1'

function HomePage() {
  return (
    <div>
      <Herosection4 />
      <div className="page-content">
        <Featurestart2 />
        <AboutL2 />
        <Multisecstart />
        <Blog />
        <Newsletter />
        {/* <TestimonialL1 /> */}
      </div>
    </div>
  )
}

export default HomePage
