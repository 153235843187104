import React from 'react';
import './SoftwareDevelopmentOffer.css'; // Import your CSS file
import a from './1.png';
import b from './2.png';
import c from './3.png';

const SoftwareDevelopmentOffer = () => {
    return (
        <section className="software-development-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="offer-title">Accelerate Your Business with Expert Software Development Solutions</h2>
                        <p className="lead offer-description">
                            Our software development services are designed to empower your business with innovative solutions. From tailored software development to seamless integration, we've got you covered.
                        </p>
                    </div>
                </div>
        
                {/* Custom Development Section */}
                <div className="row custom-dev-section">
                    <div className="col-md-6">
                        <h3>Custom Software Development</h3>
                        <p>
                            Leverage our expertise in a variety of programming languages. We craft custom solutions to address your unique business challenges and goals.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={a} alt="Custom Development" className="img-fluid" />
                    </div>
                </div>

                {/* DevOps Integration Section */}
                <div className="row devops-integration-section">
                    <div className="col-md-6">
                        <img src={b} alt="DevOps Integration" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <h3>DevOps Integration</h3>
                        <p>
                            Transform your development and deployment processes with our DevOps solutions. We implement best practices and tools to enhance collaboration, automate workflows, and ensure faster time-to-market for your applications.
                        </p>
                    </div>
                </div>

                {/* Technology Stack Section */}
                {/* <div className="row technology-stack-section">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Our Technology Stack</h3>
                        <p>
                            We work with a diverse set of technologies to deliver robust and scalable software solutions. Our technology stack includes, but is not limited to:
                        </p>
                        <ul className="list-unstyled">
                            <li>JavaScript</li>                    
                            <li>Node.js</li>
                            <li>React</li>
                            <li>React-Native</li>
                            <li>Python</li>
                            <li>C#</li>
                            <li>HTML/CSS</li>
                            <li>Java</li>
                            <li>SQL and NoSQL databases</li>
                            <li>Containerization (Docker)</li>
                            <li>Cloud Platforms (AWS, Azure, GCP)</li>
                        </ul>
                    </div>
                </div> */}

                {/* SaaS Products Section */}
                <div className="row saas-products-section">
                    <div className="col-md-6">
                        <h3>Custom SaaS Products</h3>
                        <p>
                            Elevate your business efficiency with our custom Software as a Service (SaaS) solutions. We develop user-friendly and scalable SaaS products tailored to your industry needs, providing a seamless experience for your users.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={c} alt="SaaS Products" className="img-fluid" />
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to embark on a transformative journey? Let's discuss how our software development solutions can propel your business forward.
                        </p>
                        <a href="/contact1" className="btn btn-primary btn-lg cta-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SoftwareDevelopmentOffer;
