import React, { useEffect } from 'react'
import Form2 from './Form'
import { useSelector } from 'react-redux';
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader';


function BlogSingle() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    let blog = useSelector((state) => state.blog.selectedBlog);
    const blogs = useSelector((state) => state.blog.blogItems);
    if (!blog) {
        blog = blogs[0].id
    }
    let selectedBlog = blogs.find((item) => item.id === blog);
    console.log(selectedBlog);
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Blog"} name={"Blog Single"} />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {selectedBlog &&
                                <div className="card border-0 bg-transparent">
                                    <img className="card-img-top shadow" src={require(`../../assets/images/blog/${selectedBlog.image.split('/')[3]}`)} alt="Image1" />
                                    <div className="card-body pt-5 px-0">
                                        <div>
                                            <div className="d-inline-block bg-light text-center px-2 py-1 rounded me-2">{selectedBlog.date}</div> <div className="d-inline-block btn-link">{selectedBlog.category}</div>
                                        </div>
                                        <h2 className="h5 my-4">
                                            <div className="link-title">{selectedBlog.title}</div>
                                        </h2>
                                        <p>Ladies and gentlemen, distinguished guests, and esteemed colleagues,</p>
                                    </div>
                                    <p>I stand before you today with immense pride and excitement as we embark on a groundbreaking journey with the launch of our new DevOps company. In a rapidly evolving digital landscape, where innovation is the heartbeat of progress, we have seized the opportunity to redefine excellence in the world of technology and operations.
                                    As we take our first steps, let me share our vision for this venture. Our foundation is built on the principles of collaboration, efficiency, and continuous improvement—hallmarks of the DevOps philosophy. We understand that the success of any enterprise lies not only in the quality of its products but also in the seamless orchestration of development, security, and operations.

Our commitment is not just to deliver solutions but to craft experiences that resonate with our clients and partners. We envision a future where technology is an enabler, seamlessly integrating into the fabric of organizations, fostering innovation, and driving sustainable success.

In the realm of DevOps, where every deployment is a symphony of code, infrastructure, and security, we pledge to be the conductors of this harmonious blend. Our team, comprising seasoned professionals and fresh talents alike, is driven by a shared passion for pushing boundaries, learning, and adapting to the ever-evolving technological landscape.

Transparency, accountability, and client satisfaction are the cornerstones of our approach. We aim not just to meet expectations but to exceed them consistently. Your success is our success, and we are committed to forging partnerships that stand the test of time.

In the coming months and years, anticipate with us a journey marked by milestones, innovations, and the relentless pursuit of excellence. We promise not only to keep pace with industry trends but to lead, leaving an indelible mark on the DevOps landscape.

As we celebrate this inaugural moment, let me express my heartfelt gratitude to all those who have been a part of this journey—our dedicated team, our clients, and our well-wishers. Together, we will build a legacy that resonates with the spirit of progress, collaboration, and transformative technology.

Thank you for joining us on this remarkable voyage. The best is yet to come!
                                    </p>
                                    <blockquote className="card bg-primary border-0 p-5 mt-5 text-white">"Love your neighbor as yourself." <span className="mt-2 fst-italic font-w-6">- Matthew 22:39</span>
                                    </blockquote>
                                    <div className="d-md-flex justify-conten    t-between">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0 me-4">Share It:</h6>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item"><a className="border rounded px-2 py-1 text-dark" href="/"><i className="la la-facebook" /></a>
                                                </li>
                                                <li className="list-inline-item"><a className="border rounded px-2 py-1 text-dark" href="/"><i className="la la-dribbble" /></a>
                                                </li>
                                                <li className="list-inline-item"><a className="border rounded px-2 py-1 text-dark" href="/"><i className="la la-instagram" /></a>
                                                </li>
                                                <li className="list-inline-item"><a className="border rounded px-2 py-1 text-dark" href="/"><i className="la la-twitter" /></a>
                                                </li>
                                                <li className="list-inline-item"><a className="border rounded px-2 py-1 text-dark" href="/"><i className="la la-linkedin" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex align-items-center text-md-end mt-5 mt-md-0">
                                            <h6 className="mb-0 me-4">Tags: </h6>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item"><a className="btn-link rounded d-inline-block p-2 bg-light m-1" href="/">StartUP</a>
                                                </li>
                                                <li className="list-inline-item"><a className="btn-link rounded d-inline-block p-2 bg-light m-1" href="/">Enterprise</a>
                                                </li>
                                                <li className="list-inline-item"><a className="btn-link rounded d-inline-block p-2 bg-light m-1" href="/">Collaboration</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="mt-6 shadow p-5">
                                        <div className="mb-4">
                                            <h2>All Comments</h2>
                                        </div>
                                        <div className="row">
                                            <div className="mb-4 mb-md-0 col-md-auto">
                                                <img className="img-fluid rounded shadow" alt="image1" src={require("../../assets/images/thumbnail/01.jpg")} />
                                            </div>
                                            <div className="col-md">
                                                <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">Raymond Lee</h6>  <small className="text-muted">5 Days Ago</small>
                                                </div>
                                                <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named. Primis veritus contentiones nec ad, nec et tantas semper delicatissimi.</p> <a className="btn btn-primary btn-sm" href="/">Reply</a>
                                            </div>
                                        </div>
                                        <div className="row border p-4 my-5 rounded">
                                            <div className="mb-4 mb-md-0 col-md-auto">
                                                <img className="img-fluid rounded shadow" alt="image2" src={require("../../assets/images/thumbnail/02.jpg")} />
                                            </div>
                                            <div className="col-md">
                                                <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">Dani Karie</h6>  <small className="text-muted">5 Days Ago</small>
                                                </div>
                                                <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named. Primis veritus contentiones nec ad, nec et tantas semper delicatissimi.</p> <a className="btn btn-primary btn-sm" href="/">Reply</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="mb-4 mb-md-0 col-md-auto">
                                                <img className="img-fluid rounded shadow" alt="image3" src={require("../../assets/images/thumbnail/03.jpg")} />
                                            </div>
                                            <div className="col-md">
                                                <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
                                                    <h6 className="mb-0">Karlo Bond</h6>  <small className="text-muted">5 Days Ago</small>
                                                </div>
                                                <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named. Primis veritus contentiones nec ad, nec et tantas semper delicatissimi.</p> <a className="btn btn-primary btn-sm" href="/">Reply</a>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="post-comments mt-5">
                                        <div className="mb-4">
                                            <h2>Leave A Comment</h2>
                                        </div>
                                        <Form2 />
                                    </div> */}
                                    <div />
                                </div>}

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BlogSingle
