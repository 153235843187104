import React from 'react'
import Verifycomponet from './Verifycomponent'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'

function Verify() {
  return (
    <div>
      <HerosectionHeader name={"Verify"} folder1={"Pages"} folder2={"Account"} />
      <div className="page-content">
        <Verifycomponet />
        <NewsletterL1 />
      </div>
    </div>
  )
}

export default Verify
