import React from 'react'
import Terms from '../../Componet/Terms'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import Sec1 from '../../Componet/Security/Sec1'



function TermsConditions() {
    return (
        <>
            <HerosectionHeader folder1={"pages"} folder2={"Utilities"} name={"Security Solutions"} />
            <div class="page-content">
                <Sec1></Sec1>
                <NewsletterL1 />
            </div>
        </>
    )
}

export default TermsConditions
