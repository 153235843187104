import React from 'react'
import Terms from '../../Componet/Terms'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import HE from '../../Componet/HEBREW/HE'
import L2he from '../../Componet/Feature/FeatureL2he'



function TermsConditions() {
    return (
        <>
        
            <HerosectionHeader name={"נעים להכיר, השותף הטכנולוגי שלך"} />
            <div class="page-content">
                {/* <HE></HE> */}
                <L2he></L2he>
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                         לקוחות חדשים נהנים מ-30 דקות יעוץ   
                         <strong style={{fontWeight: 'bold'}}> חינם</strong>
                        </p>
                        <a href="contact1" className="btn btn-primary btn-lg">צור קשר</a>
                    </div>
                </div>
                <NewsletterL1 />
                
            </div>
           
           
            
        </>
    )
}

export default TermsConditions
