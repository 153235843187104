import React from 'react';

const AcademySolutionsOffer = () => {
    return (
        <section className="academy-solutions-offer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Empower Learning with Our Comprehensive Academy Solutions</h2>
                        <p className="lead">
                            Explore our range of academy solutions designed to foster education, skill development, and knowledge enhancement. Elevate your learning experience with our tailored programs and resources.
                        </p>
                    </div>
                </div>

                {/* Online Courses Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Online Courses</h3>
                        <p>
                            Access high-quality, interactive online courses designed to enhance your skills and knowledge. Our academy offers a diverse range of courses across various domains, ensuring a personalized learning experience.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* <img src="/images/online-courses.jpg" alt="Online Courses" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Skill Development Programs Section */}
                <div className="row">
                    <div className="col-md-6">
                        {/* <img src="/images/skill-development.jpg" alt="Skill Development Programs" className="img-fluid" /> */}
                    </div>
                    <div className="col-md-6">
                        <h3>Skill Development Programs</h3>
                        <p>
                            Enhance your professional skills with our targeted skill development programs. We offer hands-on training, workshops, and certifications to equip you with practical expertise for career growth.
                        </p>
                    </div>
                </div>

                {/* Learning Management System (LMS) Section */}
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h3>Learning Management System (LMS)</h3>
                        <p>
                            Our advanced Learning Management System provides a seamless and interactive platform for educators and learners. Manage courses, track progress, and facilitate collaborative learning with ease.
                        </p>
                    </div>
                </div>

                {/* Professional Development Section */}
                <div className="row">
                    <div className="col-md-6">
                        <h3>Professional Development</h3>
                        <p>
                            Invest in your professional growth with our specialized professional development programs. Stay ahead in your industry by acquiring new skills and staying updated on the latest trends.
                        </p>
                    </div>
                    <div className="col-md-6">
                        {/* <img src="/images/professional-development.jpg" alt="Professional Development" className="img-fluid" /> */}
                    </div>
                </div>

                {/* Call-to-Action Section */}
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="lead">
                            Ready to embark on a learning journey? Explore our academy solutions and unlock a world of knowledge and skill development.
                        </p>
                        <a href="contact1" className="btn btn-primary btn-lg">Contact now!</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AcademySolutionsOffer;
